import customizedTable from './theme-customizations/u-table.json';
import customizedCard from './theme-customizations/u-card.json';
import customizedModal from './theme-customizations/u-modal.json';
import customizedSubrowTable from './theme-customizations/u-subrowtable.json';
import customizedTooltip from './theme-customizations/u-tooltip.json';
import customizedPageHeader from './theme-customizations/u-page-header.json';

export default defineAppConfig({
  ui: {
    primary: 'indigo',
    gray: 'slate',
    table: customizedTable,
    formGroup: {
      container: 'mt-1.5'
    },
    subrowTable: customizedSubrowTable,
    card: customizedCard,
    modal: customizedModal,
    tooltip: customizedTooltip,
    page: {
      header: customizedPageHeader
    }
  }
});
