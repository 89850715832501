import * as cryptoJS from 'crypto-js';

export function useStringEncryption () {
  const runtimeConfig = useRuntimeConfig();

  function base64Encode (input: string): string {
    return cryptoJS.enc.Base64.stringify(cryptoJS.enc.Utf8.parse(input));
  }

  function getEncryptionKey (password = '') {
    // Combine the user password and hardcoded key
    return cryptoJS.SHA256(password + runtimeConfig.public.encSecret).toString();
  }

  function encryptData (data: string, password = ''): string {
    if (data.length) {
      const key = getEncryptionKey(password);
      // hash the name with any algorithm
      const encData = cryptoJS.AES.encrypt(
        data,
        key
      ).toString();

      return encData;
    }
    return '';
  }
  function decryptData (data: string, password = ''): string {
    // decrypt the data and convert to string
    if (data?.length) {
      const key = getEncryptionKey(password);
      const decryptData = cryptoJS.AES.decrypt(
        data,
        key
      ).toString(cryptoJS.enc.Utf8);
      return decryptData;
    }
    return '';
  }

  return { encryptData, decryptData, base64Encode };
}
