import type { RouterConfig } from '@nuxt/schema';
import { useLocalStorage } from '@vueuse/core';
import type { RouteRecordRaw } from '#vue-router';
import { EASY_APP_ROUTES, PRO_APP_ROUTES } from '~/constants';
import { AppMode, LocalStorageIds } from '~/enums';

export default {
  routes: (_routes) => {
    const selectedAppMode = useLocalStorage(LocalStorageIds.uiMode, AppMode.PRO);

    const proRoutes: RouteRecordRaw[] = [
      {
        ...PRO_APP_ROUTES.wallet,
        component: () => import('~/pages/(pro)/index.vue').then(r => r.default || r)
      },
      {
        ...PRO_APP_ROUTES.channels,
        component: () => import('~/pages/(pro)/channels/index.vue').then(r => r.default || r)
      },
      {
        ...PRO_APP_ROUTES.channels.children.peer.network,
        component: () => import('~/pages/(pro)/channels/[peer]/[network]/[id]/index.vue').then(r => r.default || r)
      },
      {
        ...PRO_APP_ROUTES.channels.children.peer.asset,
        component: () => import('~/pages/(pro)/channels/[peer]/[network]/[id]/[symbol]/index.vue').then(r => r.default || r)
      },
      {
        ...PRO_APP_ROUTES.transactions,
        component: () => import('~/pages/(pro)/transactions/index.vue').then(r => r.default || r)
      },
      {
        ...PRO_APP_ROUTES.transactions.children.details,
        component: () => import('~/pages/(pro)/transactions/details/[id]/index.vue').then(r => r.default || r)
      }
    ];
    const easyRoutes: RouteRecordRaw[] = [
      {
        ...EASY_APP_ROUTES.home,
        component: () => import('~/pages/(easy)/index.vue').then(r => r.default || r)
      },
      {
        ...EASY_APP_ROUTES.unlock,
        component: () => import('~/pages/(easy)/unlock.vue').then(r => r.default || r)
      },
      {
        ...EASY_APP_ROUTES.setup,
        component: () => import('~/pages/(easy)/setup/index.vue').then(r => r.default || r)
      },
      {
        ...EASY_APP_ROUTES.setup.children.create,
        component: () => import('~/pages/(easy)/setup/create/index.vue').then(r => r.default || r)
      },
      {
        ...EASY_APP_ROUTES.setup.children.create.children.confirm,
        component: () => import('~/pages/(easy)/setup/create/confirm.vue').then(r => r.default || r)
      },
      {
        ...EASY_APP_ROUTES.setup.children.create.children.verify,
        component: () => import('~/pages/(easy)/setup/create/verify.vue').then(r => r.default || r)
      },
      {
        ...EASY_APP_ROUTES.setup.children.import,
        component: () => import('~/pages/(easy)/setup/import/index.vue').then(r => r.default || r)
      },
      {
        ...EASY_APP_ROUTES.setup.children.password,
        component: () => import('~/pages/(easy)/setup/password/index.vue').then(r => r.default || r)
      },
      {
        ...EASY_APP_ROUTES.account.children.forgotPassword,
        component: () => import('~/pages/(easy)/account/forgot-password.vue').then(r => r.default || r)
      },
      {
        ...EASY_APP_ROUTES.account.children.resetPassword,
        component: () => import('~/pages/(easy)/account/reset-password.vue').then(r => r.default || r)
      },
      {
        ...EASY_APP_ROUTES.receive,
        component: () => import('~/pages/(easy)/receive.vue').then(r => r.default || r)
      },
      {
        ...EASY_APP_ROUTES.send,
        component: () => import('~/pages/(easy)/send.vue').then(r => r.default || r)
      },
      {
        ...EASY_APP_ROUTES.swap,
        component: () => import('~/pages/(easy)/swap.vue').then(r => r.default || r)
      },
      {
        ...EASY_APP_ROUTES.transactions,
        component: () => import('~/pages/(easy)/transactions.vue').then(r => r.default || r)
      }
    ];

    // return only pro routes for production
    if (import.meta.env.PROD) {
      return proRoutes;
    } else if (!import.meta.env.PROD && import.meta.env.DEV) {
      const styleguideRoutes = _routes.filter(route => route.path.startsWith('/styleguide'));

      if (selectedAppMode.value === AppMode.PRO) {
        return [
          ...styleguideRoutes,
          ...proRoutes
        ];
      }

      return [
        ...styleguideRoutes,
        ...easyRoutes
      ];
    }

    return [];
  }
} satisfies RouterConfig;
