import { default as forgot_45password2LQqyaJKUSMeta } from "/opt/build/repo/pages/(easy)/account/forgot-password.vue?macro=true";
import { default as reset_45passwordnqAjUDe2yqMeta } from "/opt/build/repo/pages/(easy)/account/reset-password.vue?macro=true";
import { default as indexj1BqZX4TYQMeta } from "/opt/build/repo/pages/(pro)/index.vue?macro=true";
import { default as index3dMHUj3aQiMeta } from "/opt/build/repo/pages/(easy)/index.vue?macro=true";
import { default as receiveKuWsfNFwouMeta } from "/opt/build/repo/pages/(easy)/receive.vue?macro=true";
import { default as sendo82ukuWJTSMeta } from "/opt/build/repo/pages/(easy)/send.vue?macro=true";
import { default as confirmA4L9yQSKzjMeta } from "/opt/build/repo/pages/(easy)/setup/create/confirm.vue?macro=true";
import { default as index6l7kkIRil3Meta } from "/opt/build/repo/pages/(easy)/setup/create/index.vue?macro=true";
import { default as verifyqBhtBSYyDcMeta } from "/opt/build/repo/pages/(easy)/setup/create/verify.vue?macro=true";
import { default as indexbMtQLGyzzfMeta } from "/opt/build/repo/pages/(easy)/setup/import/index.vue?macro=true";
import { default as indexzzNg6LxsEWMeta } from "/opt/build/repo/pages/(easy)/setup/index.vue?macro=true";
import { default as indexkFfAZQhlRJMeta } from "/opt/build/repo/pages/(easy)/setup/password/index.vue?macro=true";
import { default as swapoprXzJQd13Meta } from "/opt/build/repo/pages/(easy)/swap.vue?macro=true";
import { default as indexNsNf0pdtelMeta } from "/opt/build/repo/pages/(pro)/transactions/details/[id]/index.vue?macro=true";
import { default as indexrsSqEbqpmdMeta } from "/opt/build/repo/pages/(pro)/transactions/index.vue?macro=true";
import { default as transactionsPO3ypo2aPMMeta } from "/opt/build/repo/pages/(easy)/transactions.vue?macro=true";
import { default as unlockb25yvxbM4XMeta } from "/opt/build/repo/pages/(easy)/unlock.vue?macro=true";
import { default as index418c35JK5sMeta } from "/opt/build/repo/pages/(pro)/channels/[peer]/[network]/[id]/[symbol]/index.vue?macro=true";
import { default as indexPjvfCLYMw1Meta } from "/opt/build/repo/pages/(pro)/channels/[peer]/[network]/[id]/index.vue?macro=true";
import { default as indexB345KLitJkMeta } from "/opt/build/repo/pages/(pro)/channels/index.vue?macro=true";
import { default as BadgeXfvD4UmUeDMeta } from "/opt/build/repo/pages/styleguide/Badge.vue?macro=true";
import { default as SEasyLabelAndValueListBvjNiViZKRMeta } from "/opt/build/repo/pages/styleguide/easy/SEasyLabelAndValueList.vue?macro=true";
import { default as SEasyPercentageSelectorNCCWdJHaiOMeta } from "/opt/build/repo/pages/styleguide/easy/SEasyPercentageSelector.vue?macro=true";
import { default as SEasyTokenEyKXfAbFTBMeta } from "/opt/build/repo/pages/styleguide/easy/SEasyToken.vue?macro=true";
import { default as SQuickActionButtonhI1hs24aS6Meta } from "/opt/build/repo/pages/styleguide/easy/SQuickActionButton.vue?macro=true";
import { default as index4cEucY7taiMeta } from "/opt/build/repo/pages/styleguide/index.vue?macro=true";
import { default as linksGTxi5erY7jMeta } from "/opt/build/repo/pages/styleguide/links.js?macro=true";
import { default as SAccordions3dFc2oUfjqMeta } from "/opt/build/repo/pages/styleguide/SAccordions.vue?macro=true";
import { default as SActionButtonlpmeXxB0m4Meta } from "/opt/build/repo/pages/styleguide/SActionButton.vue?macro=true";
import { default as SAssetChannelCardnf8PVtlaT6Meta } from "/opt/build/repo/pages/styleguide/SAssetChannelCard.vue?macro=true";
import { default as SChannelCapacityMeterUbganLjyUhMeta } from "/opt/build/repo/pages/styleguide/SChannelCapacityMeter.vue?macro=true";
import { default as SCopyToClipboardeTrxUo3yf7Meta } from "/opt/build/repo/pages/styleguide/SCopyToClipboard.vue?macro=true";
import { default as SCoreCardfUDK2pOJ0YMeta } from "/opt/build/repo/pages/styleguide/SCoreCard.vue?macro=true";
import { default as SCryptoSelectMenuesZimuX1R5Meta } from "/opt/build/repo/pages/styleguide/SCryptoSelectMenu.vue?macro=true";
import { default as SDescriptionListimfToS0amTMeta } from "/opt/build/repo/pages/styleguide/SDescriptionList.vue?macro=true";
import { default as SEasyDashboardLayoutFKun9V82bKMeta } from "/opt/build/repo/pages/styleguide/SEasyDashboardLayout.vue?macro=true";
import { default as SFullScreenLayoutUSssXZu7P9Meta } from "/opt/build/repo/pages/styleguide/SFullScreenLayout.vue?macro=true";
import { default as SHalfScreenLayoutI1Ifn1PgSoMeta } from "/opt/build/repo/pages/styleguide/SHalfScreenLayout.vue?macro=true";
import { default as SInputNumbertzziWkacItMeta } from "/opt/build/repo/pages/styleguide/SInputNumber.vue?macro=true";
import { default as SLeadTextcdbZjLA1btMeta } from "/opt/build/repo/pages/styleguide/SLeadText.vue?macro=true";
import { default as SQRDisplayModalDiRmivvatWMeta } from "/opt/build/repo/pages/styleguide/SQRDisplayModal.vue?macro=true";
import { default as SRadioCardGroupDetailedv7Ucx6rlQfMeta } from "/opt/build/repo/pages/styleguide/SRadioCardGroupDetailed.vue?macro=true";
import { default as SRadioCardGroupSimpleWZxMW1wFUfMeta } from "/opt/build/repo/pages/styleguide/SRadioCardGroupSimple.vue?macro=true";
import { default as SStepsDbvFIIrQFFMeta } from "/opt/build/repo/pages/styleguide/SSteps.vue?macro=true";
import { default as STableJGnwwGk7dRMeta } from "/opt/build/repo/pages/styleguide/STable.vue?macro=true";
import { default as SToken2yHfOMlp3MMeta } from "/opt/build/repo/pages/styleguide/SToken.vue?macro=true";
import { default as TabsMIbRn6BN2LMeta } from "/opt/build/repo/pages/styleguide/Tabs.vue?macro=true";
export default [
  {
    name: "account-forgot-password",
    path: "/account/forgot-password",
    component: () => import("/opt/build/repo/pages/(easy)/account/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "account-reset-password",
    path: "/account/reset-password",
    component: () => import("/opt/build/repo/pages/(easy)/account/reset-password.vue").then(m => m.default || m)
  },
  {
    name: index3dMHUj3aQiMeta?.name,
    path: "/",
    component: () => import("/opt/build/repo/pages/(easy)/index.vue").then(m => m.default || m),
    children: [
  {
    name: "index",
    path: "",
    component: () => import("/opt/build/repo/pages/(pro)/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "receive",
    path: "/receive",
    component: () => import("/opt/build/repo/pages/(easy)/receive.vue").then(m => m.default || m)
  },
  {
    name: "send",
    path: "/send",
    component: () => import("/opt/build/repo/pages/(easy)/send.vue").then(m => m.default || m)
  },
  {
    name: "setup-create-confirm",
    path: "/setup/create/confirm",
    component: () => import("/opt/build/repo/pages/(easy)/setup/create/confirm.vue").then(m => m.default || m)
  },
  {
    name: "setup-create",
    path: "/setup/create",
    component: () => import("/opt/build/repo/pages/(easy)/setup/create/index.vue").then(m => m.default || m)
  },
  {
    name: "setup-create-verify",
    path: "/setup/create/verify",
    component: () => import("/opt/build/repo/pages/(easy)/setup/create/verify.vue").then(m => m.default || m)
  },
  {
    name: "setup-import",
    path: "/setup/import",
    component: () => import("/opt/build/repo/pages/(easy)/setup/import/index.vue").then(m => m.default || m)
  },
  {
    name: "setup",
    path: "/setup",
    component: () => import("/opt/build/repo/pages/(easy)/setup/index.vue").then(m => m.default || m)
  },
  {
    name: "setup-password",
    path: "/setup/password",
    component: () => import("/opt/build/repo/pages/(easy)/setup/password/index.vue").then(m => m.default || m)
  },
  {
    name: "swap",
    path: "/swap",
    component: () => import("/opt/build/repo/pages/(easy)/swap.vue").then(m => m.default || m)
  },
  {
    name: transactionsPO3ypo2aPMMeta?.name,
    path: "/transactions",
    component: () => import("/opt/build/repo/pages/(easy)/transactions.vue").then(m => m.default || m),
    children: [
  {
    name: "transactions-details-id",
    path: "details/:id()",
    component: () => import("/opt/build/repo/pages/(pro)/transactions/details/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "transactions",
    path: "",
    component: () => import("/opt/build/repo/pages/(pro)/transactions/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "unlock",
    path: "/unlock",
    component: () => import("/opt/build/repo/pages/(easy)/unlock.vue").then(m => m.default || m)
  },
  {
    name: "channels-peer-network-id-symbol",
    path: "/channels/:peer()/:network()/:id()/:symbol()",
    component: () => import("/opt/build/repo/pages/(pro)/channels/[peer]/[network]/[id]/[symbol]/index.vue").then(m => m.default || m)
  },
  {
    name: "channels-peer-network-id",
    path: "/channels/:peer()/:network()/:id()",
    component: () => import("/opt/build/repo/pages/(pro)/channels/[peer]/[network]/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "channels",
    path: "/channels",
    component: () => import("/opt/build/repo/pages/(pro)/channels/index.vue").then(m => m.default || m)
  },
  {
    name: "styleguide-Badge",
    path: "/styleguide/Badge",
    component: () => import("/opt/build/repo/pages/styleguide/Badge.vue").then(m => m.default || m)
  },
  {
    name: "styleguide-easy-SEasyLabelAndValueList",
    path: "/styleguide/easy/SEasyLabelAndValueList",
    component: () => import("/opt/build/repo/pages/styleguide/easy/SEasyLabelAndValueList.vue").then(m => m.default || m)
  },
  {
    name: "styleguide-easy-SEasyPercentageSelector",
    path: "/styleguide/easy/SEasyPercentageSelector",
    component: () => import("/opt/build/repo/pages/styleguide/easy/SEasyPercentageSelector.vue").then(m => m.default || m)
  },
  {
    name: "styleguide-easy-SEasyToken",
    path: "/styleguide/easy/SEasyToken",
    component: () => import("/opt/build/repo/pages/styleguide/easy/SEasyToken.vue").then(m => m.default || m)
  },
  {
    name: "styleguide-easy-SQuickActionButton",
    path: "/styleguide/easy/SQuickActionButton",
    component: () => import("/opt/build/repo/pages/styleguide/easy/SQuickActionButton.vue").then(m => m.default || m)
  },
  {
    name: "styleguide",
    path: "/styleguide",
    meta: index4cEucY7taiMeta || {},
    component: () => import("/opt/build/repo/pages/styleguide/index.vue").then(m => m.default || m)
  },
  {
    name: "styleguide-links",
    path: "/styleguide/links",
    component: () => import("/opt/build/repo/pages/styleguide/links.js").then(m => m.default || m)
  },
  {
    name: "styleguide-SAccordions",
    path: "/styleguide/SAccordions",
    component: () => import("/opt/build/repo/pages/styleguide/SAccordions.vue").then(m => m.default || m)
  },
  {
    name: "styleguide-SActionButton",
    path: "/styleguide/SActionButton",
    component: () => import("/opt/build/repo/pages/styleguide/SActionButton.vue").then(m => m.default || m)
  },
  {
    name: "styleguide-SAssetChannelCard",
    path: "/styleguide/SAssetChannelCard",
    component: () => import("/opt/build/repo/pages/styleguide/SAssetChannelCard.vue").then(m => m.default || m)
  },
  {
    name: "styleguide-SChannelCapacityMeter",
    path: "/styleguide/SChannelCapacityMeter",
    component: () => import("/opt/build/repo/pages/styleguide/SChannelCapacityMeter.vue").then(m => m.default || m)
  },
  {
    name: "styleguide-SCopyToClipboard",
    path: "/styleguide/SCopyToClipboard",
    component: () => import("/opt/build/repo/pages/styleguide/SCopyToClipboard.vue").then(m => m.default || m)
  },
  {
    name: "styleguide-SCoreCard",
    path: "/styleguide/SCoreCard",
    component: () => import("/opt/build/repo/pages/styleguide/SCoreCard.vue").then(m => m.default || m)
  },
  {
    name: "styleguide-SCryptoSelectMenu",
    path: "/styleguide/SCryptoSelectMenu",
    meta: SCryptoSelectMenuesZimuX1R5Meta || {},
    component: () => import("/opt/build/repo/pages/styleguide/SCryptoSelectMenu.vue").then(m => m.default || m)
  },
  {
    name: "styleguide-SDescriptionList",
    path: "/styleguide/SDescriptionList",
    component: () => import("/opt/build/repo/pages/styleguide/SDescriptionList.vue").then(m => m.default || m)
  },
  {
    name: "s-easy-dashboard-layout",
    path: "/styleguide/SEasyDashboardLayout",
    meta: SEasyDashboardLayoutFKun9V82bKMeta || {},
    component: () => import("/opt/build/repo/pages/styleguide/SEasyDashboardLayout.vue").then(m => m.default || m)
  },
  {
    name: "s-full-screen-layout",
    path: "/styleguide/SFullScreenLayout",
    meta: SFullScreenLayoutUSssXZu7P9Meta || {},
    component: () => import("/opt/build/repo/pages/styleguide/SFullScreenLayout.vue").then(m => m.default || m)
  },
  {
    name: "s-half-screen-layout",
    path: "/styleguide/SHalfScreenLayout",
    meta: SHalfScreenLayoutI1Ifn1PgSoMeta || {},
    component: () => import("/opt/build/repo/pages/styleguide/SHalfScreenLayout.vue").then(m => m.default || m)
  },
  {
    name: "styleguide-SInputNumber",
    path: "/styleguide/SInputNumber",
    component: () => import("/opt/build/repo/pages/styleguide/SInputNumber.vue").then(m => m.default || m)
  },
  {
    name: "styleguide-SLeadText",
    path: "/styleguide/SLeadText",
    component: () => import("/opt/build/repo/pages/styleguide/SLeadText.vue").then(m => m.default || m)
  },
  {
    name: "styleguide-SQRDisplayModal",
    path: "/styleguide/SQRDisplayModal",
    component: () => import("/opt/build/repo/pages/styleguide/SQRDisplayModal.vue").then(m => m.default || m)
  },
  {
    name: "styleguide-SRadioCardGroupDetailed",
    path: "/styleguide/SRadioCardGroupDetailed",
    component: () => import("/opt/build/repo/pages/styleguide/SRadioCardGroupDetailed.vue").then(m => m.default || m)
  },
  {
    name: "styleguide-SRadioCardGroupSimple",
    path: "/styleguide/SRadioCardGroupSimple",
    component: () => import("/opt/build/repo/pages/styleguide/SRadioCardGroupSimple.vue").then(m => m.default || m)
  },
  {
    name: "styleguide-SSteps",
    path: "/styleguide/SSteps",
    component: () => import("/opt/build/repo/pages/styleguide/SSteps.vue").then(m => m.default || m)
  },
  {
    name: "styleguide-STable",
    path: "/styleguide/STable",
    component: () => import("/opt/build/repo/pages/styleguide/STable.vue").then(m => m.default || m)
  },
  {
    name: "styleguide-SToken",
    path: "/styleguide/SToken",
    component: () => import("/opt/build/repo/pages/styleguide/SToken.vue").then(m => m.default || m)
  },
  {
    name: "styleguide-Tabs",
    path: "/styleguide/Tabs",
    component: () => import("/opt/build/repo/pages/styleguide/Tabs.vue").then(m => m.default || m)
  }
]