export default defineAppConfig({
  ui: {
    verticalNavigation: {
      icon: {
        base: 'w-5 h-5 text-gray-600 group-hover:text-primary-500 dark:group-hover:text-primary-400',
        active: 'text-primary-500 dark:text-primary-400'
      },
      active: 'text-primary-500 dark:text-primary-400',
      base: 'hover:text-primary-500 dark:hover:text-primary-400 text-gray-900 dark:text-gray-50',
      font: 'font-medium',
      padding: 'px-3 py-2.5',
      size: 'text-base'
    },
    notifications: {
      position: 'bottom-0 right-0'
    }
  }
});
