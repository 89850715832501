<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useLocalStorage } from '@vueuse/core';
import { useHydraNodeStore } from '~/stores/hydra-node';
import NodeEventHelper from '~/components/pro/helper/NodeEventHelper.vue';
import { AppMode, LocalStorageIds } from '~/enums';
import { EASY_APP_ROUTES } from '~/constants';
import { Mnemonic } from 'hydra-node';

// TODO adapt store https://github.com/Offchain-DEX/web-dex/issues/280
const hydraNodeStore = useHydraNodeStore();
const { readLocalStorageMnemonic } = useMnemonicManager();
const router = useRouter();
const route = useRoute();

const { mnemonic, isPackageInitialized } =
  storeToRefs(hydraNodeStore);

const selectedAppMode = useLocalStorage(LocalStorageIds.uiMode, AppMode.PRO);

onBeforeMount(async () => {
  await hydraNodeStore.initializePackage();
});

onMounted(() => {
  if (selectedAppMode.value === AppMode.PRO) { return; }

  handleEasyValidationFlow();
});

function handleEasyValidationFlow () {
  const persistedMnemonic = readLocalStorageMnemonic();

  if (!mnemonic.value && !persistedMnemonic && route.name !== EASY_APP_ROUTES.setup.name) {
    router.push(EASY_APP_ROUTES.setup.path);
  }

  if (!mnemonic.value && persistedMnemonic) {
    mnemonic.value = Mnemonic.fromString(persistedMnemonic);
  }
}

watchEffect(async () => {
  if (mnemonic.value && isPackageInitialized.value) {
    await hydraNodeStore.initializeHydraNode();
  }
});

</script>
<template>
  <div
    class="u-border-gray-200 relative mx-auto max-w-[120rem] 3xl:border-2"
  >
    <NuxtPage />
    <UNotifications />
    <NodeEventHelper />
    <UModals />
  </div>
</template>
