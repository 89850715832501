import { useLocalStorage } from '@vueuse/core';
import { useStringEncryption } from './useStringEncryption';
import { Mnemonic } from 'hydra-node';
import { LocalStorageIds } from '~/enums/localStorage';
import { MnemonicLength } from '~/enums';

export function useMnemonicManager () {
  const { encryptData, decryptData, base64Encode } = useStringEncryption();

  const encryptedMnemonicKey = base64Encode(LocalStorageIds.mnemonic);
  const encryptedWalletPasswordKey = base64Encode(LocalStorageIds.walletPassword);

  const _walletPassword = computed({
    get () {
      return useLocalStorage(encryptedWalletPasswordKey, '').value;
    },
    set (value: string) {
      useLocalStorage(encryptedWalletPasswordKey, '').value = value;
    }
  });

  const _mnemonic = computed({
    get () {
      return useLocalStorage(encryptedMnemonicKey, '').value || '';
    },
    set (value: string) {
      useLocalStorage(encryptedMnemonicKey, '').value = value;
    }
  });

  const setWalletPassword = (password: string) => {
    _walletPassword.value = encryptData(password);
  };

  const removeWalletPassword = () => {
    _walletPassword.value = '';
  };

  function readWalletPassword ():string {
    return decryptData(_walletPassword.value);
  }

  const resetLocalStorageMnemonic = () => {
    _mnemonic.value = '';
  };
  /**
   * If user has set a password, we encrypt the mnemonic with the password + salt.
   * Therefore, if someone delete the password in the local storage, he won't be able to access the wallet.
   * Because the to decode the mnemonic, the password + salt is needed.
   *
   * This is meant to be used only within TESTNET environment.
   * For mainnet we need the signer extension
   * @param mnemonicValue
   * @param password
   */
  const setLocalStorageMnemonic = (mnemonicValue = '') => {
    _mnemonic.value = encryptData(mnemonicValue, readWalletPassword());
  };

  const readLocalStorageMnemonic = () => {
    return decryptData(_mnemonic.value, readWalletPassword());
  };

  const generateMnemonic = (length: MnemonicLength) => {
    return Mnemonic.generateNew(length).toString();
  };

  return {
    removeWalletPassword,
    setWalletPassword,
    resetLocalStorageMnemonic,
    setLocalStorageMnemonic,
    generateMnemonic,
    readLocalStorageMnemonic
  };
}
